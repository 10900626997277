<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="Inventory Transfer"
        @sidebar="sidebarFilter()"
        :hasDropdown="false"
        :filter="filter"
        placeholder="Inventory Transfer No."
        @search="handleSearch"
        routerPath="/inventory-transfer/detail/0"
        :hasFilter="true"
      >
      </HeaderPanel>
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-tabs content-class="" v-model="tabIndex">
          <b-tab
            v-for="status of statusList"
            :key="status.id"
            :value="status.id"
            @click="onTabChange(status.id, $event)"
          >
            <template v-slot:title>
              <div>
                <span>{{ status.name }}</span>
                <span> ({{ status.count }}) </span>
              </div>
            </template>
            <b-table
              striped
              hover
              :busy="isBusy"
              :items="documentList"
              :fields="fields"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(document_no)="data">
                <router-link :to="'/inventory-transfer/detail/' + data.item.id"
                  ><span class="underline">{{
                    data.item.document_no
                  }}</span></router-link
                >
              </template>
              <template v-slot:cell(remark)="data">
                {{ data.item.remark || "-" }}
              </template>
              <template v-slot:cell(status_name)="{ item }">
                <div
                  :class="{
                    'text-success': item.status == 3,
                    'text-gray': item.status == 6,
                    'text-danger': item.status == 5,
                    'text-warning':
                      item.status == 7 || item.status == 2 || item.status == 1,
                  }"
                >
                  {{ item.status_name || "-" }}
                </div>
              </template>
              <template v-slot:cell(created_time)="data">
                <div class="text-center text-black my-2">
                  {{ $moment(data.item.created_time).format("DD/MM/YYYY") }}
                  <br />
                  {{ $moment(data.item.created_time).format("HH:mm") }}
                </div>
              </template>
              <template v-slot:cell(source_branch.branch_name)="data">
                <div>{{ data.item.source_branch.branch_name }}</div>
                <!-- <br /> -->
                <div>รหัส : {{ data.item.source_branch.branch_id }}</div>
              </template>
              <template v-slot:cell(destination_branch.branch_name)="data">
                <div>{{ data.item.destination_branch.branch_name }}</div>
                <!-- <br /> -->
                <div>รหัส : {{ data.item.destination_branch.branch_id }}</div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-flex align-items-center justify-content-center">
                  <font-awesome-icon
                    @click="toggleDetail(data)"
                    icon="clipboard"
                    title="detail"
                    class="mr-2 pointer"
                  />
                  <font-awesome-icon
                    icon="pencil-alt"
                    title="detail"
                    class="ml-2 pointer"
                    @click="$router.push(`/inventory-transfer/detail/${data.item.id}`)"
                  />

                  <!-- <b-dropdown no-caret ref="dropdown" class="" variant="link" right>
                <template #button-content>
                  <font-awesome-icon
                    icon="ellipsis-v"
                    title="detail"
                    class="text-black pointer"
                  />
                </template>

                <b-dropdown-item
                  v-if="data.item.cancellable"
                  @click="confirmModal(1, data.item.id)"
                >
                  ยกเลิก
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.confirm_transfer"
                  @click="confirmModal(2, data.item.id)"
                >
                  ยืนยันจัดส่ง
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="data.item.confirm_receive"
                  @click="confirmModal(3, data.item.id)"
                  >ยืนยันรับของ</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="data.item.ready_to_ship"
                  @click="confirmModal(4, data.item)"
                  >ยืนยันการจัดของ</b-dropdown-item
                >
                <b-dropdown-item
                  v-if="data.item.exportable"
                  @click="exportDetail(data)"
                >
                  Export To Excel
                </b-dropdown-item>
                <b-dropdown-item
                  @click="goDetail(`${data.item.id}?tabIndex=1`)"
                >
                  ประวัติ
                </b-dropdown-item>
              </template> -->
                  <!-- </b-dropdown> -->
                </div>
              </template>
              <template #row-details="row">
                <b-table
                  striped
                  hover
                  :busy="row.item.isBusy"
                  :items="row.item.row_detail"
                  :fields="fieldsDetail"
                  show-empty
                  empty-text="No matching records found"
                >
                  <template v-slot:cell(product_name)="data">
                    <div>
                      {{ data.item.display_name }}

                      <template v-if="data.item.is_serial"
                        >({{ data.item.serial_name }})</template
                      >
                    </div>
                  </template>

                  <template v-slot:table-busy>
                    <div class="text-center text-black my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong class="ml-2">Loading...</strong>
                    </div>
                  </template>
                </b-table>
              </template>

              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="handleChangeTake"
              :pageOptions="pageOptions"
              :filter="filter"
              :rows="rows"
              @pagination="pagination"
            />
          </b-tab>
        </b-tabs>
        <SideBarFilter
          ref="filter"
          :filter="filter"
          :placeholder="'Search Name'"
          @clearFilter="clearFilter"
          @searchAll="filterActions"
          :hideStatusFilter="false"
          :hideSearchBar="true"
        >
          <template v-slot:filter-option>
            <b-row>
              <b-col cols="12">
                <InputSelect
                  title="Source Branch"
                  :options="branchList"
                  textField="name"
                  valueField="id"
                  v-model="filter.source_branch"
                >
                  <template v-slot:option-first>
                    <b-form-select-option value="" disabled
                      >-- Select Source Branch --</b-form-select-option
                    >
                    <b-form-select-option value="">All</b-form-select-option>
                  </template>
                </InputSelect>
              </b-col>
              <b-col cols="12">
                <InputSelect
                  title="Destination Branch"
                  :options="branchList"
                  textField="name"
                  valueField="id"
                  v-model="filter.destination_branch"
                >
                  <template v-slot:option-first>
                    <b-form-select-option value="" disabled
                      >-- Select Destination branch --</b-form-select-option
                    >
                    <b-form-select-option value="">All</b-form-select-option>
                  </template>
                </InputSelect>
              </b-col>
              <b-col cols="12">
                <InputSelect
                  title="Transfer Form"
                  :options="TransferFormList"
                  textField="name"
                  valueField="id"
                  v-model="filter.inventory_transfer_document_id"
                >
                  <template v-slot:option-first>
                    <b-form-select-option value="" disabled
                      >-- Select Transfer Form --</b-form-select-option
                    >
                    <b-form-select-option value="">All</b-form-select-option>
                  </template>
                </InputSelect>
              </b-col>
            </b-row>
          </template>
        </SideBarFilter>
      </div>
    </div>
  </div>
</template>
<script>
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    OtherLoading,
  },
  data() {
    return {
      isLoading: false,
      statusList: [],
      items: [],
      documentList: [],
      data: {
        total: 0,
        total_success: 0,
        total_pending: 0,
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      filter: {
        search: "",
        inventory_transfer_document_id: "",
        inventory_transfer_status: [0],
        page: 1,
        form: "",
        source_branch: "",
        destination_branch: "",
        take: 10,
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      tabIndex: 0,
      fields: [
        { label: "Date", key: "created_time" },
        { label: "Inventory Transfer No.", key: "document_no" },
        { label: "Status", key: "status_name" },
        {
          label: "Source Branch",
          key: "source_branch.branch_name",
          class: "text-left",
        },
        {
          label: "Destination Branch",
          key: "destination_branch.branch_name",
          class: "text-left",
        },
        { label: "Note", key: "remark" },
        { label: "", key: "action" },
      ],
      fieldsDetail: [
        { label: "#", key: "" },
        { label: "Product", key: "product_name" },
        { label: "Quantity", key: "quantity" },
      ],
      branchList: [],
      TransferFormList: [],
    };
  },
  async created() {
    await this.getStatus();
    await this.getList();
    await this.getBranchList();
    await this.getTransferForm();
  },
  methods: {
    async getList() {
      let payload = { ...this.filter };
      this.isBusy = true;
      this.documentList = [];
      const response = await this.axios.post(
        `/InventoryTransfer/Transfer/List`,
        payload
      );

      this.rows = response.data.detail.total_count;
      this.documentList = response.data.detail.transfer_list.map((el) => {
        return { ...el, row_detail: [], isBusy: false };
      });

      this.isBusy = false;
      this.isLoading = false;
    },
    async getStatus() {
      this.isBusy = true;
      let payload = { ...this.filter };

      const res = await this.axios.post(
        `/InventoryTransfer/Transfer/ListStatus`,
        payload
      );
      if (res.data.result == 1) {
        this.statusList = res.data.detail;
      }

      this.isBusy = false;
    },
    async getBranchList() {
      const res = await this.axios(`/branch/listAll`);
      this.branchList = res.data.detail;
    },
    async getTransferForm() {
      const res = await this.axios(`/InventoryTransfer/Document/List/0`);
      this.TransferFormList = res.data.detail;
    },
    async handleSearch() {
      this.filter.page = 1;
      await this.getStatus();
      await this.getList();
    },
    async onTabChange(val, e) {
      this.filter.inventory_transfer_status = [val];
      this.filter.page = 1;
      this.showing = 1;
      this.showingTo = this.filter.take;
      await this.getStatus();
      await this.getList();
    },
    pagination(val) {
      this.filter.page = val;
      this.getList();
    },
    async handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      await this.getStatus();
      await this.getList();
    },
    async toggleDetail(row) {
      row.item.isBusy = true;
      await this.$nextTick();
      console.log(row);
      row.toggleDetails();
      if (row.item.row_detail.length == 0) this.getRowDetail(row.item);
      else row.item.isBusy = false;
    },
    async getRowDetail(row) {
      const res = await this.axios(
        `/InventoryTransfer/Transfer/${row.id}/Product`
      );
      row.row_detail = res.data.detail;
      row.isBusy = false;
      await this.$nextTick();
    },
    sidebarFilter() {
      this.$refs.filter.show();
    },
    async filterActions() {
      this.filter.page = 1;
      await this.getStatus();
      await this.getList();
    },
    clearFilter() {
      this.filter = {
        search: "",
        inventory_transfer_document_id: "",
        inventory_transfer_status: [0],
        page: 1,
        form: "",
        source_branch: "",
        destination_branch: "",
        take: 10,
      };
    },
  },
};
</script>
